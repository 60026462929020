import React, {useEffect, useState} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import Loading from "../Loading/Loading";
import {useLocation, useNavigate} from "react-router-dom";
import Title from "../Comps/Title";
import {UserIcon, UserPlusIcon} from "@heroicons/react/16/solid";

const Game = () => {
    const [games, setGames] = useState([])
    //const [code, setCode] = useState("")
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();

    const joinGame = (code) => {
        navigate('/play', {state: {code: code}})
    }

    const getGames = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/getGames`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setGames(result)
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }

    useEffect(() => {
        console.log(location)
        getGames()
    }, [location])


    if (loaded) {
        return (
            <>
                <Title title={"Join"} desc={"Select a quiz to join."} />
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {games.map((game) => (
                        <li key={game.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <img className="h-16 w-16 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{game.title}</h3>
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Host: {game.username}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Questions: {game.questions}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Code: {game.code}</p>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {joinGame(game.code)}}>
                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Join
                                        </div>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <div className="relative cursor-not-allowed inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Info
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                {/*
               <div className="quiz">
                <div className="join-box" style={{color:"white"}}>
                    <TextField
                        variant="outlined"
                        color="secondary"
                        style={{marginRight: "10px"}}
                        label="Code"
                        focused
                        onChange={e => setCode(e.target.value)}
                    />
                    <Button variant="contained" color="secondary" onClick={() => {joinGame(code)}}>Join</Button>
                </div>
                <div className="quiz-box">
                    {game.map((item) => (
                        <div key={item.id} style={{height:"max-content"}} className="quiz-container">
                            <div className="quiz-title">
                                <Avatar style={{width:"100px", height:"100px"}} variant="rounded" src={item.pic} />
                                <div className="title">
                                    <div className="info">Title</div>
                                    <div>{item.title}</div>
                                    <div className="info">Host</div>
                                    <div>{item.username}</div>
                                    <div className="info">Questions</div>
                                    <div>{item.questions}</div>
                                    <div className="info">Room Code</div>
                                    <div>{item.code}</div>
                                </div>
                            </div>
                            <div className="quiz-buttons">
                                <Button variant="contained" color="primary" onClick={() => {joinGame(item.code)}}>Join</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
                */}
            </>
        );
    } else {
        return <Loading />
    }
};

export default Game;
