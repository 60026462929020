import React, {useState, useEffect} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import Title from "../Comps/Title";
import {PencilSquareIcon, EyeIcon, PlusCircleIcon} from "@heroicons/react/16/solid";
import {handleResponse} from "../Scripts/handle-response";
import Input from "../Comps/Input";
import Button from "../Comps/Button"
import Loading from "../Loading/Loading";
import {useNavigate} from "react-router-dom";

const Pub = () => {
    const [user] = useState(authenticationService.currentUserValue)
    const [open, setOpen] = useState(false)
    const [crawls, setCrawls] = useState([])
    const [userPubs, setUserPubs] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [date, setDate] = useState(new Date())
    const [current, setCurrent] = useState(1)
    let navigate = useNavigate()

    const getCrawls = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/getCrawls`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setCrawls(result)
                    getUserPubs()
                }, (error) => {console.log(error)}
            )
    }

    const getUserPubs = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user':user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/findUserPubs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setUserPubs(result)
                    setLoaded(true)
                },
                (error) => {console.log(error)}
            )
    }

    const joinCrawl = (id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'crawl':id, 'user':user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/joinUserCrawl`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    getUserPubs()
                    console.log(result)
                },
                (error) => {console.log(error)}
            )
    }

    const test = () => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                new Notification('1!', { body: 'Check your dashboard' });
            }
            window.setTimeout(() => {
                if (permission === 'granted') {
                    new Notification('New Message!', { body: 'Check your dashboard' });
                }
            }, 500)
        });
    }

    const newCrawl = () => {
        let crawl = {
            title: title,
            desc: desc,
            date: date,
            current_pub: current,
         }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'crawl':crawl})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/newCrawl`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    getCrawls()
                    setOpen(false)
                },
                (error) => {console.log(error)}
            )
    }

    useEffect(() => {
        getCrawls()
    }, [])

    let extra = <></>
    if (user.admin) {
        extra = (
            <div className="text-right">
                <Button title={"New"} onClick={() => setOpen(true)} />
                <Button title={"Test"} onClick={() => test()} />
            </div>
        )
    }

    if (loaded) {
        return (
            <>
                <Title title={"Crawls"} desc={"Pub Crawl"} extra={extra} />

                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {crawls.map((crawl) => (
                        <li key={crawl.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                {/*<img className="h-14 w-14 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />*/}
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{crawl.title}</h3>
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Desc: {crawl.desc}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Date: {new Date(crawl.date).getDay()} / {new Date(crawl.date).getMonth()} / {new Date(crawl.date).getFullYear()}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Current: {crawl.current_pub}</p>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        {userPubs.some(obj => obj.crawl === crawl.id) ? (
                                            <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {
                                                navigate(`${crawl.id}/view`, {})
                                            }}>
                                                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                View
                                            </div>
                                        ) : (
                                            <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {joinCrawl(crawl.id)}}>
                                                <PlusCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Join
                                            </div>
                                        )}
                                    </div>
                                    {user.admin ? (
                                        <div className="-ml-px flex w-0 flex-1">
                                            <div className="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {
                                                navigate(`${crawl.id}/edit`, {})
                                            }}>
                                                <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Edit
                                            </div>
                                        </div>
                                    ) : <></>}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={open ? "absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity z-[101] flex justify-center items-center" : "-z-10 absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center opacity-0"}>
                    <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 w-full lg:w-96 h-auto">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Setup Crawl</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <Input
                                label={"Title"}
                                placeholder={"Crawl Name"}
                                onChange={e => setTitle(e.target.value)}
                            />
                            <Input
                                label={"Desc"}
                                placeholder={"Crawl Desc"}
                                onChange={e => setDesc(e.target.value)}
                            />
                            <Input
                                label={"Date"}
                                type={"date"}
                                value={date}
                                placeholder={0}
                                onChange={e => setDate(e.target.value)}
                            />
                            <Input
                                label={"Current Pub"}
                                placeholder={1}
                                onChange={e => setCurrent(e.target.value)}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                type="button"
                                className="inline-flex mr-3 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={() => newCrawl()}
                            >
                                Create
                            </button>
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (<Loading />)
    }
}

export default Pub;
