import React from "react";
import Title from "../../Comps/Title";
//import Snowfall from "react-snowfall";
import { MinusCircleIcon } from '@heroicons/react/24/outline'

class Menu extends React.Component {
    render() {
        return (
            <>
                <Title title={"KASHOOT.CO.UK"} desc={`ROOM - ${this.props.code}`} />
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                    {this.props.players.map((user, index) => (
                        <li key={index} className="col-span-1 flex rounded-md shadow-sm">
                            <div className={'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'}>
                                <img src={user.pic} />
                            </div>
                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <div className="font-medium text-gray-900 hover:text-gray-600">
                                        {user.username}
                                    </div>
                                    <p className="text-gray-500">{user.title}</p>
                                </div>
                                <div className="flex-shrink-0 pr-2">
                                    <button
                                        type="button"
                                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <MinusCircleIcon onClick={ () => this.props.next()} className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </>
        );
    }
}

export default Menu;
