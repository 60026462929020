import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import { Dialog, Transition } from '@headlessui/react'
import {
    HomeIcon,
    PlayIcon,
    CommandLineIcon,
    PencilSquareIcon,
    SwatchIcon,
    UsersIcon,
    CreditCardIcon,
    AdjustmentsVerticalIcon,
    MapIcon,
    Bars3BottomLeftIcon,
    XMarkIcon,
    Bars3Icon,
    QrCodeIcon,
    PowerIcon,
    CubeTransparentIcon,
    HomeModernIcon
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Header() {
    const [user] = useState(authenticationService.currentUserValue)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState({inGame: false, code: 69420})
    const location = useLocation();
    const [shrink, setShrink] = useState(false)
    const [navigation, setNavigation] = useState([
        { name: 'Home', link: '/', icon: HomeIcon, current: true, perms: null },
        { name: 'Play', link: '/join', icon: PlayIcon, current: false, perms: null },
        { name: 'Host', link: '/quizzes', icon: CommandLineIcon, current: false, perms: "host" },
        { name: 'Create', link: '/creator', icon: PencilSquareIcon, current: false, perms: "create" },
        { name: 'Cards', link: '/cards', icon: SwatchIcon, current: false, perms: null },
        { name: 'Users', link: '/profile', icon: UsersIcon, current: false, perms: null },
        { name: 'Store', link: '/store', icon: CreditCardIcon, current: false, perms: null },
        { name: 'Admin', link: '/admin', icon: AdjustmentsVerticalIcon, current: false, perms: "admin" },
        { name: 'Pub', link: '/pub', icon: HomeModernIcon, current: false, perms: "pub" },
        { name: 'Roadmap', link: '/roadmap', icon: MapIcon, current: false, perms: null },
        { name: 'Patch Notes', link: '/patchnotes', icon: Bars3BottomLeftIcon, current: false, perms: null },
        { name: 'Pee Pee Poo Poo Maia Bingo', link: '/bingo', icon: QrCodeIcon, current: false, perms: "friend" },
    ])

    const checkInGame = useCallback(() => {
        console.log(location)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/checkIn`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setData(result)
                }
            )
    }, [user, location])

    useEffect(() => {
        const updatedNavigation = navigation.map(item =>
            item.link === location.pathname ? { ...item, current: true } : { ...item, current: false }
        );
        setNavigation(updatedNavigation)
        if (location.pathname !== "/play" && location.pathname !== "/host") {
            checkInGame()
        }
    }, [location, checkInGame]);

    const logout = () => {
        authenticationService.logout(authenticationService.currentUserValue);
        window.location.reload();
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>

                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src="https://i.imgur.com/uvlMpvK.png"
                                            alt="Kashoot"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul className="-mx-2 space-y-1">
                                                    {navigation.map((item) => {
                                                        if (!item.perms || user[item.perms]) {
                                                            return (
                                                                <li key={item.name}>
                                                                    <Link to={item.link}>
                                                                        <div
                                                                            className={classNames(
                                                                                item.current
                                                                                    ? 'bg-gray-50 text-indigo-600'
                                                                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                            )}
                                                                        >
                                                                            <item.icon
                                                                                className={classNames(
                                                                                    item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                                    'h-6 w-6 shrink-0'
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                            {item.name}
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        } else {
                                                            return ""
                                                        }
                                                    })}
                                                </ul>
                                            </li>
                                            <li className="-mx-6 mt-auto">
                                                <div className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                                    <CubeTransparentIcon
                                                        className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                                        aria-hidden="true"
                                                    />
                                                    <span aria-hidden="true">{user.mealdealz}</span>
                                                </div>
                                                <div onClick={logout} className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                                    <PowerIcon
                                                        className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                                        aria-hidden="true"
                                                    />
                                                    <span aria-hidden="true">Logout</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className={!shrink ? "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col transition-all" : "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[73px] lg:flex-col transition-all"}>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-20 w-auto"
                            src="https://i.imgur.com/uvlMpvK.png"
                            alt="Kashoot"
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {navigation.map((item) => {
                                        if (!item.perms || user[item.perms]) {
                                            return (
                                                <li key={item.name}>
                                                    <Link to={item.link}>
                                                        <div
                                                            className={classNames(
                                                                item.current
                                                                    ? 'bg-gray-50 text-indigo-600'
                                                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                    'h-6 w-6 shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {!shrink && item.name}
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </ul>
                            </li>
                            <li className="-mx-6 mt-auto">
                                <div className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50" onClick={() => setShrink(!shrink)}>
                                    <CubeTransparentIcon
                                        className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                        aria-hidden="true"
                                    />
                                    {!shrink && (<span aria-hidden="true">{user.mealdealz}</span>)}
                                </div>
                                <div onClick={logout} className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                    <PowerIcon
                                        className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                        aria-hidden="true"
                                    />
                                    {!shrink && (<span aria-hidden="true">Logout</span>)}
                                </div>
                                <Link to={"/profile/"+user.username+""}>
                                    <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                        <img
                                            className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                                            src={user.pic}
                                            alt=""
                                        />
                                        {!shrink && (<>
                                            <span className="sr-only">Your profile</span>
                                            <span aria-hidden="true">{user.username}</span>
                                        </>)}
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
                <Link to={"/profile/"+user.username+""}>
                    <span className="sr-only">Your profile</span>
                    <img
                        className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                        src={user.pic}
                        alt=""
                    />
                </Link>
            </div>
        </>
    );
}

export default Header;
