import React, {useEffect, useState} from "react";
import {authenticationService} from "../../Scripts/authentication.service";
import {handleResponse} from "../../Scripts/handle-response";
import Loading from "../../Loading/Loading";
import {ChevronDownIcon, UserIcon, UserPlusIcon} from "@heroicons/react/16/solid";
import Button from "../../Comps/Button";

const Pub = () => {
    const [users, setUsers] = useState([])
    const [pubs, setPubs] = useState([])
    const [crawls, setCrawls] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [searched, setSearched] = useState(false)
    const [userIndex, setUserIndex] = useState(0)
    const [crawlIndex, setCrawlIndex] = useState(0)

    const getUsers = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/getUsers`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setUsers(result)
                    getCrawls()
                },
                (error) => {console.error(error)}
            )
    }

    const getCrawls = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/getCrawls`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setCrawls(result)
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }

    const search = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':crawls[crawlIndex].id, 'user':users[userIndex].id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/findUserCrawlPubs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setPubs(result)
                    setSearched(true)
                },
                (error) => {console.log(error)}
            )
    }

    const togglePubbed = (id, pubbed) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':id, 'pubbed':pubbed})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/updatePubUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                    search()
                },
                (error) => {console.log(error)}
            )
    }

    useEffect(() => {
        getUsers()
    }, [])

    if (loaded) {
        return (
            <>
                <div className="mt-2 grid grid-cols-[auto,auto,max-content] gap-2">
                    <select
                        defaultValue={users[userIndex]}
                        aria-label="Select a user"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                        onChange={(e) => {setUserIndex(parseInt(e.target.value))}}
                    >
                        {users.map((user, index) => (
                            <option key={index} value={index}>{user.username}</option>
                        ))}
                    </select>
                    <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                    />

                    <select
                        defaultValue={crawls[crawlIndex]}
                        aria-label="Select a crawl"
                        className="col-start-2 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                        onChange={(e) => {setCrawlIndex(parseInt(e.target.value))}}
                    >
                        {crawls.map((crawl, index) => (
                            <option key={index} value={index}>{crawl.title}</option>
                        ))}
                    </select>
                    <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-2 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                    />

                    <div className="col-start-3 row-start-1">
                        <Button title={"Search"} buttonSize={3} onClick={() => search()} />
                    </div>
                </div>

                <div className="mt-2">
                    {searched ? (
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {pubs.map((pub) => (
                                <li key={pub.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                                        {/*<img className="h-14 w-14 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />*/}
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">{pub.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Pubbed: {pub.pubbed}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Start Time: {new Date(pub.start_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">End Time: {new Date(pub.end_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200">
                                            <div className="flex w-0 flex-1">
                                                <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {togglePubbed(pub.pubbed_id, pub.pubbed ? 0 : 1)}}>
                                                    <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    Toggle Pubbed
                                                </div>
                                            </div>
                                            <div className="-ml-px flex w-0 flex-1">
                                                <div className="relative cursor-not-allowed inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                    <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    ---
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )
                        : <Loading />
                    }
                </div>

            </>
        )
    } else {
        return <Loading />
    }
};

export default Pub;
