import React from 'react';
import {Description, Field, Switch, Label} from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const KSlider = ({
                    id,
                    name,
                    onChange,
                    className,
                    label,
                    desc,
                    defaultChecked= false,
                    checked = false,
                    disabled = false,
                }) => {
    return (
        <Field as="div" className={className}>
            <span className="flex flex-grow flex-col">
                {!!label && (
                    <Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                        {label}
                    </Label>
                )}
                {!!desc && (
                    <Description as="span" className="text-sm text-gray-500">
                        {desc}
                    </Description>
                )}
            </span>
            <Switch
                id={id}
                name={name}
                defaultChecked={defaultChecked}
                checked={checked}
                onChange={onChange}
                className={classNames(
                    checked ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                />
            </Switch>
        </Field>
    );
};

export default KSlider;
