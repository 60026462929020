import React, {useEffect, useRef, useState} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import io from "socket.io-client";
import {useLocation, useNavigate} from "react-router-dom";
import {handleResponse} from "../Scripts/handle-response";
import Start from "./components/start";
import PreAnswer from "./components/pre-answer";
import Answer from "./components/answer";
import Answered from "./components/answered";
import Ready from "./components/ready";
import Finished from "./components/finished";

const Play = () => {
    const [user] = useState(authenticationService.currentUserValue)
    const [code, setCode] = useState(0)
    const [stage, setStage] = useState(0)
    const [results, setResults] = useState(null)

    const location = useLocation();
    const navigate = useNavigate()

    const socket = useRef(null)
    const dataRef = useRef(null)

    const joinGame = () => {
        socket.current.emit('join', {user, code})
    }

    const getResults = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user.id, 'code': code})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/getGameUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                setResults(result[0])
                setStage(5)
            })
    }

    const listenStage = () => {
        socket.current.on('stage', ({stage, data}) => {
            if (stage === 5) {
                getResults()
            } else {
                dataRef.current = data
                console.log("data:",data)
                console.log("datacurrent:",dataRef.current)
                setStage(stage)
            }
            console.log(stage)
        })
    }

    const socketOff = () => {
        console.log("Testing off")
        socket.current.removeAllListeners();
        socket.current.disconnect()
    }

    const listenError = () => {
        socket.current.on('error', ({msg}) => {
            console.log("error", msg)
            socket.current.emit('leave', {code})
            socketOff()
            navigate('/join', {state: {msg: msg}})
        })

        socket.current.on('connect_error', (err) => {
            console.log(`connect_error due to ${err.message}`);
            socketOff()
            navigate('/join', {state: {msg: err.message}})
        })

        socket.current.on('connect_timeout', (err) => {
            console.log(`connect_timeout due to ${err.message}`);
            socketOff()
            navigate('/join', {state: {msg: err.message}})
        })
    }

    const answer = (answer) => {
        console.log("----------")
        console.log("ANSWERING")
        console.log("USER:", user)
        console.log("ANSWER:", answer)
        console.log("code:", code)
        socket.current.emit('answering', {user, answer, code})
        setStage(3)
    }

    useEffect(() => {
        if (code === 0) return
        joinGame()
        listenError()
        listenStage()
    }, [code])

    useEffect(() => {
        if (location.state) {
            socket.current = io(process.env.REACT_APP_API);

            setCode(location.state.code)
        } else {
            socketOff()
            navigate('/join')
        }
    }, [])

    switch (stage){
        default:
            return <Start user={user.username} code={code}/>
        case 1:
            console.log(dataRef.current)
            return <PreAnswer user={user.username} code={code} data={dataRef.current} />
        case 2:
            return <Answer user={user.username} code={code} answer={answer} data={dataRef.current} />
        case 3:
            return <Answered user={user.username} code={code} data={dataRef.current} />
        case 4:
            return <Ready user={user.username} code={code} data={dataRef.current} />
        case 5:
            return <Finished user={user.username} results={results} code={code} data={dataRef.current} />
    }
};

export default Play;
