import React, {useState} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import Title from "../Comps/Title";
import { UserIcon, HomeModernIcon, Cog8ToothIcon, CommandLineIcon } from '@heroicons/react/20/solid'
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import WIP from "../Errors/wip";
import Users from "./Pages/Users";
import Pub from "./Pages/Pub";

const tabs = [
    { name: 'Users', href: '#', icon: UserIcon },
    { name: 'Pub', href: '#', icon: HomeModernIcon },
    { name: 'Quiz\'s', href: '#', icon: CommandLineIcon },
    { name: 'Site Settings', href: '#', icon: Cog8ToothIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Admin = () => {
    const [user] = useState(authenticationService.currentUserValue)

    const [page, setPage] = useState(0)

    const getPage = () => {
        switch (page) {
            case 0:
                return <Users />
            case 1:
                return <Pub />
            case 2:
                return <WIP />
            case 3:
                return <WIP />
            default:
                return <WIP />
        }
    }

    return (
        <>
            <Title title={"Admin"} desc={"Admin Test"} />

            {/* Mobile Page Selector */}
            <div className="grid grid-cols-1 sm:hidden">
                <select
                    defaultValue={tabs[page]}
                    aria-label="Select a tab"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={(e) => {setPage(parseInt(e.target.value))}}
                >
                    {tabs.map((tab, index) => (
                        <option key={index} value={index}>{tab.name}</option>
                    ))}
                </select>
                <ChevronDownIcon
                    aria-hidden="true"
                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                />
            </div>

            {/* Desktop Page Selector */}
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab, index) => (
                            <a
                                key={tab.name}
                                href={tab.href}
                                aria-current={index === page ? 'page' : undefined}
                                className={classNames(
                                    index === page
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium',
                                )}
                                onClick={() => {setPage(index)}}
                            >
                                <tab.icon
                                    aria-hidden="true"
                                    className={classNames(
                                        index === page ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 size-5',
                                    )}
                                />
                                <span>{tab.name}</span>
                            </a>
                        ))}
                    </nav>
                </div>
            </div>

            {/* Pages */}
            {getPage()}
        </>
    )
}

export default Admin;
