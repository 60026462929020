import React, {useState} from "react";
import Title from "../Comps/Title";

const PatchNotes2 = () => {
    const [patches] = useState([
        {
            title: "Hello World",
            version: "0.0.1",
            changes: [
                "Lorem ipsum dolor sit amet",
                "consectetur adipiscing elit",
            ],
        },
        {
            title: "Version 1",
            version: "0.1.0",
            changes: [
                "Basic Hosting",
                "Basic Client",
                "Starter Quiz's",
            ],
        },
        {
            title: "Fake Integration",
            version: "0.1.1",
            changes: [
                "Fake option answer",
            ],
        },
        {
            title: "Ui Changes",
            version: "0.1.2",
            changes: [
                "Making hosting info visible",
                "Making client info visible",
                "Displaying bar answers clearly",
                "Displaying stats clearly",
            ],
        },
        {
            title: "DB integration",
            version: "0.1.3",
            changes: [
                "Saving stats into DB",
                "Putting lobby into DB",
            ],
        },
        {
            title: "Protection",
            version: "0.1.4",
            changes: [
                "Adding host privet route",
                "Adding alerts",
                "Adding transitions",
                "Better username matching",
            ],
        },
        {
            title: "Kashoot 2.0",
            version: "0.2.0",
            changes: [
                "Admin base page",
                "Login",
                "Users",
                "Create quiz base page",
                "Header",
                "Updated home page",
                "Updated join page",
                "New user page",
                "New user page",
                "Profiles",
            ],
        },
        {
            title: "New play / Host Area",
            version: "0.2.1",
            changes: [
                "New Play",
                "New Host",
                "Styles Change",
            ],
        },
        {
            title: "Cards / Create",
            version: "0.2.2",
            changes: [
                "Card Area",
                "Profile Cards",
                "Quiz Creation",
            ],
        },
        {
            title: "Fixes",
            version: "0.2.3",
            changes: [
                "Host Fixes",
                "Play Fixes",
                "Create Fixes",
            ],
        },
        {
            title: "IDK Animation Updates",
            version: "0.2.4",
            changes: [
                "Point animation",
                "Remove transition",
                "Bar animation update",
            ],
        },
        {
            title: "F**k you zach",
            version: "0.2.5",
            changes: [
                "Zach is a c**t",
            ],
        },
        {
            title: "Update Material UI",
            version: "0.2.6",
            changes: [
                "Update material ui",
            ],
        },
        {
            title: "Style / Loading",
            version: "0.2.7",
            changes: [
                "Making sure all api elements load properly",
                "IDK restyle some stuff",
            ],
        },
        {
            title: "Ye old Update",
            version: "0.2.8",
            changes: [
                "404",
                "Work in progress",
                "Card fixes",
            ],
        },
        {
            title: "Fixes",
            version: "0.2.9",
            changes: [
                "Fix Timer",
                "Fix Cards Again",
                "Update Animations",
                "Fix Routing",
            ],
        },
        {
            title: "Roadmap",
            version: "0.2.10",
            changes: [
                "Roadmap page",
            ],
        },
        {
            title: "Patch Notes",
            version: "0.2.11",
            changes: [
                "Patch notes",
                "Why am I doing this?",
            ],
        },
        {
            title: "Socket Upgrade",
            version: "0.2.12",
            changes: [
                "Update socket version",
                "Changed Roadmap styling",
                "Fixed server closing",
                "Removed old code",
            ],
        },
        {
            title: "Snackbar",
            version: "0.2.13",
            changes: [
                "IDK where to put this",
                "Confirm Store purchases",
                "Confirm profile update",
                "Confirm quiz creation",
            ],
        },
        {
            title: "Upgraded React",
            version: "0.2.14",
            changes: [
                "Upgraded to React 18",
                "Made everything function based",
                "Class based components is lame right",
                "Fuccc all the sockets broke in function components",
            ],
        },
        {
            title: "Router Upgrade",
            version: "0.2.15",
            changes: [
                "Upgraded Router",
                "Idk its better for passing data between pages",
                "No cap it actually is",
                "Also permission pages work better :)",
            ],
        },
        {
            title: "Bingo",
            version: "0.2.16",
            changes: [
                "Created a bingo minigame",
                "Made it private based on perms",
                "Sorry",
            ],
        },
        {
            title: "Tailwind Update",
            version: "0.3.0",
            changes: [
                "Added tailwind lol",
                "Rebuilt Every page",
                "Like litterly everthing in tailwind",
                "Like going to remove MUI",
                "Like all CSS is gone :)",
                "Themes coming soon :)",
            ],
        },
        {
            title: "Fixes",
            version: "0.3.1",
            changes: [
                "New User redirect Fix",
                "Added Email to signup",
                "Added EMail to account edit",
                "Logout on mobile",
                "Logo on mobile",
                "Updated react router"
            ],
        },
        {
            title: "Tailwind Update Part2",
            version: "0.3.2",
            changes: [
                "New pages added",
                "- join game ( still needs manual code field )",
                "- host game ( still needs modal finishing touches )",
                "- patch notes final",
                "- roadmap final",
                "- all user pages",
                "- profiles still need more features ( cards / friends / quiz's made )"
            ],
        },
        {
            title: "Secret Bingo Game",
            version: "0.3.3",
            changes: [
                "Secret Bingo Game",
                "Fixed roadmap",
            ],
        },
        {
            title: "More Pages Done",
            version: "0.3.4",
            changes: [
                "- Host stuff",
                "- Play stuff",
                "- Pub crawl private utility",
                "- Admin stuff",
            ],
        },
    ])

    return (
        <>
            <Title title={"Patch Notes"} desc={"Idk see whats happened init."} />
            <ul className="space-y-6">
                {patches.reverse().map((item, index) => (
                    <li key={index} className="relative flex gap-x-4">
                        <div className="-bottom-6 absolute left-0 top-0 flex w-12 justify-center">
                            <div className="w-px bg-gray-200"/>
                        </div>
                        <img
                            src="https://i.imgur.com/N77gEfT.gif"
                            alt=""
                            className="relative mt-3 h-12 w-12 flex-none p-2 rounded-full ring-5 ring-gray-50 bg-gray-50 object-cover"
                        />
                        <div className="flex-auto bg-white rounded-md p-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4">
                                <div className="py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="font-medium text-gray-900">{item.title}</span>
                                </div>
                                <div className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                    {item.version}
                                </div>
                            </div>
                            <div className="text-sm leading-6 text-gray-500">
                                <ul>
                                    {item.changes.map((change, index) => (
                                        <li key={index}>
                                            {change}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default PatchNotes2;
