import React, {useEffect, useState} from "react";
import {authenticationService} from "../../Scripts/authentication.service";
import {handleResponse} from "../../Scripts/handle-response";
import Loading from "../../Loading/Loading";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";
import Input from "../../Comps/Input";
import Slider from "../../Comps/Slider";
const Users = () => {
    const [users, setUsers] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    const [editUser, setEditUser] = useState({})

    const getUsers = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/getUsersAdmin`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setUsers(result)
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }

    const updateUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': editUser})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/updateUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                },
                (error) => {console.error(error)}
            )
    }

    const openEditUser = (user) => {
        let temp = users.filter(obj => obj.id === user)[0]
        temp.password = "negative"
        setEditUser(temp)
        setOpen(true)
    }

    const updateUserValue = (value, target) => {
        let temp = {...editUser}
        temp[target] = value
        setEditUser(temp)
    }

    useEffect(() => {
        getUsers()
    }, [])

    if (loaded) {
        return (
            <>
                <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {users.map((user) => (
                        <li key={user.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{user.username}</h3>
                                        {!!user.admin && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-red-400 px-1.5 py-0.5 text-xs font-medium text-red-950 ring-1 ring-inset ring-red-800/20">
                                                  Admin
                                                </span>
                                        )}
                                        {!!user.host && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-blue-300 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                                  Host
                                                </span>
                                        )}
                                        {!!user.create && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                  Creator
                                                </span>
                                        )}
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Hours Alive: {((new Date() - new Date(user.date_created)) / (1000 * 60 * 60)).toFixed(2)}</p>
                                </div>
                                <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 object-cover" src={user.pic} alt="" />
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {openEditUser(user.id)}}>
                                            <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Edit
                                        </div>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <div className="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                <div className={open ? "absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity z-[101] flex justify-center items-center" : "-z-10 absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center opacity-0"}>
                    <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 w-full lg:w-96 h-auto">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Edit User</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <Input
                                label={"Username"}
                                placeholder={"Username"}
                                value={editUser.username}
                                onChange={e => updateUserValue(e.target.value, e.target.name)}
                                name={"username"}
                            />
                            <Input
                                label={"Email"}
                                placeholder={"Email"}
                                value={editUser.email}
                                onChange={e => updateUserValue(e.target.value, e.target.name)}
                                name={"email"}
                            />
                            <Input
                                label={"Profile Picture"}
                                placeholder={"Picture"}
                                value={editUser.pic}
                                onChange={e => updateUserValue(e.target.value, e.target.name)}
                                name={"pic"}
                            />
                            <Input
                                label={"Password"}
                                placeholder={"Password"}
                                type={"password"}
                                value={editUser.password}
                                onChange={e => updateUserValue(e.target.value, e.target.name)}
                                name={"password"}
                            />
                            <Slider
                                label={"Admin"}
                                onChange={e => updateUserValue(e, "admin")}
                                checked={editUser.admin}
                            />
                            <Slider
                                label={"Host"}
                                onChange={e => updateUserValue(e, "host")}
                                checked={editUser.host}
                            />
                            <Slider
                                label={"Create"}
                                onChange={e => updateUserValue(e, "create")}
                                checked={editUser.create}
                            />
                            <Slider
                                label={"Friend"}
                                onChange={e => updateUserValue(e, "friend")}
                                checked={editUser.friend}
                            />
                            <Slider
                                label={"Pub"}
                                onChange={e => updateUserValue(e, "pub")}
                                checked={editUser.pub}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                type="button"
                                className="inline-flex mr-3 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={() => updateUser()}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return <Loading />
    }
};

export default Users;
