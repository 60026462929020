import React from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {Description, Field, Input, Label} from '@headlessui/react'

const KInput = ({
                   id,
                   type = "string",
                   name,
                   value = "",
                   placeholder,
                   autocomplete,
                   required = false,
                   onChange,
                   error = false,
                   errorMsg,
                   className,
                   label,
                   desc,
                   disabled = false,
               }) => {
    return (
        <Field className={className}>
            {!!label && (
                <Label htmlFor={name} className={'block text-sm font-medium leading-6 text-gray-900'}>{label}</Label>
            )}
            {!!desc && (
                <Description>{desc}</Description>
            )}
            <div className="mt-2 relative">
                <Input
                    id={id}
                    name={name}
                    type={type}
                    defaultValue={value}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                    required={required}
                    onChange={onChange}
                    className={error
                        ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    }
                    disabled={disabled}
                />
                {error && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                    </div>
                )}
            </div>

            <p className="mt-2 text-sm text-red-600" id="email-error">
                {error && errorMsg}
            </p>
        </Field>
    );
};

export default KInput;
