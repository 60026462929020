import React, {useState, useEffect} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import Title from "../Comps/Title";
import {UserIcon, UserPlusIcon} from "@heroicons/react/16/solid";
import {handleResponse} from "../Scripts/handle-response";
import Input from "../Comps/Input";
import Button from "../Comps/Button"
import Loading from "../Loading/Loading";
import {useParams} from "react-router-dom";

const CrawlEdit = () => {
    const [user] = useState(authenticationService.currentUserValue)
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const [link, setLink] = useState(false)
    const [crawlPubs, setCrawlPubs] = useState([])
    const [allPubs, setAllPubs] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [name, setName] = useState("")
    const [desc, setDesc] = useState("")
    const [img, setImg] = useState("")
    const [lat, setLat] = useState(0.0)
    const [long, setLong] = useState(0.0)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const getCrawlPubs = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/findCrawlPubs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setCrawlPubs(result)
                    getAllPubs()
                }, (error) => {console.log(error)}
            )
    }

    const getAllPubs = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/getPubs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setAllPubs(result)
                    setLoaded(true)
                }, (error) => {console.log(error)}
            )
    }

    const handleStartTime = (event) => {
        const [hours, minutes] = event.target.value.split(":").map(Number);
        const newStartDate = startDate;
        newStartDate.setHours(hours, minutes);
        setStartDate(newStartDate);
    };

    const handleEndTime = (event) => {
        const [hours, minutes] = event.target.value.split(":").map(Number);
        const newEndDate = endDate;
        newEndDate.setHours(hours, minutes);
        setEndDate(newEndDate);
    };

    const linkPub = (pub) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'pub':pub, 'crawl':id, 'order':crawlPubs.length+1})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/joinCrawl`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    getCrawlPubs()
                },
                (error) => {console.log(error)}
            )
    }

    const newPub = () => {
        let pub = {
            name: name,
            desc: desc,
            img: img,
            lat: lat,
            long: long,
            start_time: startDate,
            end_time: endDate,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'pub':pub})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/newPub`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    getAllPubs()
                    setOpen(false)
                },
                (error) => {console.log(error)}
            )
    }

    useEffect(() => {
        getCrawlPubs()
    }, [])

    let extra = <></>
    if (user.admin) {
        extra = (
            <div className="text-right">
                <Button title={"New"} onClick={() => setOpen(true)} />
                <Button title={"Link"} onClick={() => setLink(true)} />
            </div>
        )
    }

    if (loaded) {
        return (
            <>
                <Title title={"Crawl Edit"} desc={"Edit the crawl"} extra={extra} />

                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {crawlPubs.map((pub) => (
                        <li key={pub.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                {/*<img className="h-14 w-14 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />*/}
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{pub.name}</h3>
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Lat: {pub.lat}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Long: {pub.long}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Start Time: {new Date(pub.start_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">End Time: {new Date(pub.end_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <div className="relative cursor-not-allowed -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Edit
                                        </div>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <div className="relative cursor-not-allowed inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Unlink
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={open ? "absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity z-[101] flex justify-center items-center" : "-z-10 absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center opacity-0"}>
                    <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 w-full lg:w-96 h-auto">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">New Pub</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <Input
                                label={"Name"}
                                value={name}
                                placeholder={"Pub Name"}
                                onChange={e => setName(e.target.value)}
                            />
                            <Input
                                label={"Desc"}
                                value={desc}
                                placeholder={"Pub Desc"}
                                onChange={e => setDesc(e.target.value)}
                            />
                            <Input
                                label={"Image"}
                                value={img}
                                placeholder={"Pub Image"}
                                onChange={e => setImg(e.target.value)}
                            />
                            <Input
                                label={"Latitude"}
                                value={lat}
                                placeholder={0.0}
                                onChange={e => setLat(e.target.value)}
                            />
                            <Input
                                label={"Longitude"}
                                value={long}
                                placeholder={0.0}
                                onChange={e => setLong(e.target.value)}
                            />
                            <Input
                                label={"Start Time"}
                                type={"time"}
                                value={startDate}
                                placeholder={0}
                                onChange={e => handleStartTime(e)}
                            />
                            <Input
                                label={"End Time"}
                                type={"time"}
                                value={endDate}
                                placeholder={0}
                                onChange={e => handleEndTime(e)}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                type="button"
                                className="inline-flex mr-3 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={() => newPub()}
                            >
                                Create
                            </button>
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

                <div className={link ? "absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity z-[101] flex justify-center items-center" : "-z-10 absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center opacity-0"}>
                    <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 w-full lg:w-3/5 h-auto">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Link Pub</h3>
                        <div className="mt-5">
                            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                {allPubs.map((pub) => (
                                    <li key={pub.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                                            {/*<img className="h-14 w-14 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />*/}
                                            <div className="flex-1 truncate">
                                                <div className="flex items-center space-x-3">
                                                    <h3 className="truncate text-sm font-medium text-gray-900">{pub.name}</h3>
                                                </div>
                                                <p className="mt-1 truncate text-sm text-gray-500">Lat: {pub.lat}</p>
                                                <p className="mt-1 truncate text-sm text-gray-500">Long: {pub.long}</p>
                                                <p className="mt-1 truncate text-sm text-gray-500">Start Time: {new Date(pub.start_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                                <p className="mt-1 truncate text-sm text-gray-500">End Time: {new Date(pub.end_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="-mt-px flex divide-x divide-gray-200">
                                                <div className="flex w-0 flex-1">
                                                    {crawlPubs.some(obj => obj.id === pub.id) ? (
                                                        <div className="relative cursor-not-allowed -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            Linked
                                                        </div>
                                                    ) : (
                                                        <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => linkPub(pub.id)}>
                                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            Link
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="-ml-px flex w-0 flex-1">
                                                    <div className="relative cursor-not-allowed inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                        <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        ---
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                                    onClick={() => setLink(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    } else {
        return (<Loading />)
    }
}

export default CrawlEdit;
