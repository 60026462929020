import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import Loading from "../Loading/Loading";
import Title from "../Comps/Title";
import {UserIcon, UserPlusIcon} from "@heroicons/react/16/solid";
import Input from "../Comps/Input";
import Slider from "../Comps/Slider";

function Quiz() {
    const user = authenticationService.currentUserValue
    const [quizs, setQuizs] = useState([])
    const [id, setId] = useState(0)
    const [delay, setDelay] = useState(0)
    const [priv, setPriv] = useState(false)
    const [open, setOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate();

    const getQuizs = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/quiz/getQuizs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setQuizs(result)
                    setLoaded(true)
                }, (error) => {console.log(error)}
            )
    }

    useEffect(() => {
        getQuizs()
    }, [])

    const openHost = (id) => {
        setOpen(true)
        setId(id)
    }

    const createGame = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'code':Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000, 'host':user.id, 'quiz':id, 'delay': delay, 'private':priv})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/addGame`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    navigate('/host', {state: {id: result.insertId, quiz: id}})
                },
                (error) => {console.log(error)}
            )
    }


    if (loaded) {
        return (
            <>
                <Title title={"Host"} desc={"Select a quiz to host."} />
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {quizs.map((game) => (
                        <li key={game.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <img className="h-14 w-14 flex-shrink-0 rounded-2xl bg-gray-300 object-cover" src={game.pic} alt="" />
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{game.title}</h3>
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Questions: {game.questions}</p>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <div className="relative cursor-pointer -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" onClick={() => {openHost(game.id)}}>
                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Host
                                        </div>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <div className="relative cursor-not-allowed inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Solo
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={open ? "absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity z-[101] flex justify-center items-center" : "-z-10 absolute top-0 left-0 w-dvw h-dvh bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center opacity-0"}>
                    <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 w-full lg:w-96 h-auto">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Setup Game</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <Input
                                label={"Delay"}
                                placeholder={0}
                                onChange={e => setDelay(e.target.value)}
                            />
                            <Slider
                                label={"Private"}
                                onChange={setPriv}
                                checked={priv}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                type="button"
                                className="inline-flex mr-3 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={createGame}
                            >
                                Host
                            </button>
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
                {/*
                <div className="quiz">
                    <div className="quiz-box">
                        {quiz.map((item) => (
                            <div key={item.id} className="quiz-container">
                                <div className="quiz-title">
                                    <Avatar style={{width:"100px", height:"100px"}} variant="rounded" src={item.pic} />
                                    <div className="title">
                                        <div className="info">Title</div>
                                        <div>{item.title}</div>
                                        <div className="info">Questions</div>
                                        <div>{item.questions}</div>
                                    </div>
                                </div>
                                <div className="quiz-buttons">
                                    <Button variant="contained" color="primary" onClick={() => openHost(item.id)}>Host</Button>
                                    <Button variant="contained" color="primary" disabled>Solo</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={open ? "open-host" : "hidden"}>
                    <div className="box">
                        <TextField defaultValue={delay} variant="outlined" onChange={e => setDelay(e.target.value)} label="Delay"></TextField>
                        <FormControlLabel
                            style={{marginTop: "10px", display: "flex", justifyContent: "center"}}
                            control={
                                <Switch
                                    checked={priv}
                                    onChange={() => setPriv(!priv)}
                                    color="primary"
                                />
                            }
                            label="Private"
                        />
                        <Button variant="contained" color="primary" style={{marginTop: "10px"}} onClick={createGame}>Start</Button>
                        <Button variant="contained" color="primary" style={{marginTop: "10px"}} onClick={() => setOpen(false)}>Close</Button>
                    </div>
                </div>
                */}
            </>
        )
    } else {
        return (<Loading />)
    }
}

export default Quiz;
