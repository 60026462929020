import React from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {Description, Field, Label} from '@headlessui/react'

const KButton = ({
                    id,
                    name,
                    title,
                    onClick,
                    error = false,
                    errorMsg,
                    className,
                    label,
                    desc,
                    disabled = false,
                    buttonSize = 1
                }) => {

    const size = [
        "rounded px-2 py-1 text-xs",
        "rounded px-2 py-1 text-sm",
        "rounded-md px-2.5 py-1.5 text-sm",
        "rounded-md px-3 py-2 text-sm",
        "rounded-md bg-indigo-600 px-3.5 py-2.5"
    ]

    return (
        <Field className={className}>
            {!!label && (
                <Label htmlFor={name} className={'block text-sm font-medium leading-6 text-gray-900'}>{label}</Label>
            )}
            {!!desc && (
                <Description>{desc}</Description>
            )}
            <div className="mt-2 relative">
                <button
                    id={id}
                    name={name}
                    disabled={disabled}
                    type="button"
                    className={"bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 " + size[buttonSize]}
                    onClick={onClick}
                >
                    {title}
                </button>
                {error && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                    </div>
                )}
            </div>

            <p className="mt-2 text-sm text-red-600" id="email-error">
                {error && errorMsg}
            </p>
        </Field>
    );
};

export default KButton;
